import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKamil as author } from 'data/authors';
import img from 'img/blog/badgebackground.png';
import img1 from 'img/blog/badgeimg.png';

const Content = () => {
  return (
    <Post>
      <p>
        Would you like to share your favorite apps with the public? Maybe you’d like to show your
        customers that you’re using GDPR-compliant tools?
      </p>
      <p>
        If you enjoy using LiveSession, now you have a chance to express it and get 5,000 sessions
        for free!
      </p>
      <p>
        <img
          src={img1}
          alt="Two versions of LiveSession badges."
          title="Add a LiveSession badge to your page!"
        />
      </p>
      <p>
        Add our badge on your website to get extra sessions. Just paste a piece of code in the
        footer and let us know via email – it’s that simple!
      </p>
      <p>
        <a href="/badge/" target="_blank" rel="noopener noreferrer">
          Read more about badges
        </a>{' '}
        and claim your sessions today.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'LiveSession badge',
  url: '/blog/livesession-badge/',
  description: `Add a LiveSession badge to your website. Get 5,000 sessions for free!`,
  author,
  img,
  imgSocial: img,
  date: '2020-06-14',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
